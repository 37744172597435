@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@font-face {
	font-family: "chimi";
	src: url("NanumPenScript-Regular.ttf") format("truetype");
}

html, body {
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}
.App {
	font-family: "chimi";
	font-size: xx-large;
	margin: 0;
	padding: 0;
	height: 100%;
	background-image: url("/public/images/logos/gleek_website_bg.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	text-align: center;
	padding-top: 60px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main-content {
	flex: 1;
	display: flex;
	flex-direction: column;
}

/*Nav Styling*/
#nav-bar {
	width: 100%;
	background-color: #2d9bbd;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border: 3px solid #2c2c2c;
}
.nav-menu {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.nav-menu li {
	float: left;
	color: #fffdfd;
}
.nav-menu li a {
	display: block;
	color: white;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 40px;
}
.nav-menu li a:hover {
	text-decoration: underline;
}
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.rotate-soyjak {
	animation: rotate 5s linear infinite;
	width: 95px;
}

/*Gleek info frame*/
@keyframes swing {
	0% {
		transform: perspective(1000px) rotateY(-10deg);
	}
	100% {
		transform: perspective(1000px) rotateY(10deg);
	}
}
.gleek-info {
	width: 600px;
	background-color: #2d9bbd;
	color: white;
	text-align: center;
	border: 5px solid #1e1d1d;
	border-radius: 25px;
	animation: swing 3.5s infinite alternate ease-in-out;
	box-shadow: 0 0 5px 1px #fbfbfb;
	margin-top: 80px;
	margin-bottom: 20px;
	line-height: 0.9;
	z-index: 0;
}

/* Game Styles */
.game-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px;
}
.game-top-level {
	position: relative;
	width: 1200px;
	height: 600px;
	border: 13px solid #011217;
	border-radius: 20px;
	margin: 20px auto;
	box-shadow: 0 0 6px 3px #ffffff;
	overflow: hidden;
}
.game-overlay {
	position: absolute;
	top: 10px;
	left: 10px;
	color: white;
}
.game-over-message {
	position: absolute;
	top: 12%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 1.5em;
	z-index: 100;
}
.level-display {
	position: absolute;
	top: 0%;
	left: 50%;
	transform: translateX(-50%);
	color: #fff;
	font-size: 1.2em;
	z-index: 100;
}
.score-display {
	position: absolute;
	top: 4%;
	left: 0.5%;
	color: #fff;
	font-size: 1em;
	z-index: 100;
	user-select: none;
}
.start-game-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%);
	color: #fff;
	z-index: 100;
	cursor: pointer;
	background-color: #6eb6c8;
	color: white;
	border: 5px solid #1e1d1d;
	border-radius: 25px;
	box-shadow: 0 0 5px 1px #ffffff;
	padding: 10px 20px;
	font-size: 1.2em;
	border-radius: 20px;
	transition: background-color 0.3s;
	font-family: "chimi";
	user-select: none;

	&:hover {
		background-color: #549aa8;
	}
}
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.blinking-text {
    animation: blink 1s linear infinite;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2em;
    z-index: 101;
	user-select: none;
}


.sound-toggle-button {
	position: absolute;
	top: 5%;
	left: 97.2%;
	transform: translateX(-50%);
	background-color: #6eb6c8;
	color: white;
	border: 5px solid #1e1d1d;
	border-radius: 25px;
	box-shadow: 0 0 5px 1px #ffffff;
	border-radius: 20px;
	transition: background-color 0.3s;
	cursor: pointer;
	width: 42px;
	height: 42px;
	user-select: none;
}
.sound-toggle-button:hover {
	background-color: #549aa8;
}

.game-over-message,
.level-display,
.info-message,
.instructions-modal {
	user-select: none;
}

/*Press 'i'*/
.info-message {
	margin-top: 320px;
	color: #fff;
	font-size: 0.9em;
	z-index: 100;
}
.instructions-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgb(189, 230, 240);
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 10px;
	border-radius: 10px;
	display: none;
	z-index: 100;
	width: 700px;
}
.instructions-modal.show {
	display: block;
}

/*NPC Name*/
.npc-name-display {
	position: absolute;
	top: 0%;
	left: 50%;
	transform: translateX(-50%);
	color: #fff;
	font-size: 1em;
	z-index: 100;
	user-select: none;
}

/*Timeline info*/
.timeline-container {
	width: 100%;
	margin: auto;
	margin-top: 20px;
	overflow: hidden;
	position: relative;
	max-width: 1200px;
	user-select: none;
}
.timeline {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 20px 0;
}
.timeline-phase {
	flex: 0 1 30%;
	text-align: center;
	position: relative;
	background-color: #1b1b1d;
	color: white;
	border-radius: 15px;
	border: 1px solid #4d5358;
	box-shadow: 0 10px 20px rgba(103, 102, 102, 0.2),
		0 6px 6px rgba(134, 129, 129, 0.2), inset 0 0 10px rgba(184, 180, 180, 0.1);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.timeline-phase:hover {
	transform: translateY(-5px);
	box-shadow: 0 15px 30px rgba(172, 168, 168, 0.4),
		0 10px 10px rgba(208, 199, 199, 0.2);
}
.timeline-phase h3 {
	margin: 0 0 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}
.timeline-phase ul {
	list-style-type: none;
	padding: 0;
	margin-top: 15px;
	margin-bottom: 0;
}
.timeline-arrow {
	flex-grow: 1;
	height: 2px;
	background: #ddd;
	position: relative;
}
.timeline-arrow::before,
.timeline-arrow::after {
	content: "";
	position: absolute;
	top: -5px;
	right: -8px;
	border: solid transparent;
	height: 0;
	width: 0;
	pointer-events: none;
}
.timeline-arrow::before {
	border-color: rgba(136, 183, 213, 0);
	border-left-color: #ddd;
	border-width: 6px;
	margin-top: -6px;
}
.strikethrough {
	text-decoration: line-through;
}

/* Gleekenomics */
.gleekenomics-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 3px solid #2d9bbd;
	margin: 20px 0;
	padding: 20px;
	border-radius: 15px;
	box-shadow: 0 0 5px 1px #26aaec;
	border: 4px solid #ffffff;
}
.gleekenomics-container-img {
	max-width: 100%;
	height: auto;
	max-height: 400px;
	border-radius: 15px;
}

/*Team Styling*/
.dox-event {
	max-width: 1300px;
	margin: auto;
	padding: 20px;
}
.dox-intro {
	color: white;
	margin-top: -15px;
	margin-bottom: -30px;
	text-align: center;
}
.dox-intro h2 {
	margin-bottom: -10px;
}
.dox-intro h3 {
	margin-top: 5px;
}
.section-team {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50vw;
	margin: 10px;
	color: #ffffff;
	border-radius: 15px;
	box-shadow: 0 0 5px 1px #26aaec;
	border: 4px solid #ffffff;
	flex: 1;
	min-width: 250px;
	max-width: 3000px;
	background-color: #000000;
}
.team-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	margin-bottom: 40px;
	max-width: 1300px;
}
.team-member {
	width: 25px;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	max-width: 350px;
}
.team-member-img {
	width: 200px;
	height: auto;
	border-radius: 50%;
	margin-bottom: -50px;
	text-decoration: none;
}
.team-member-description {
	color: white;
	text-decoration: line-through;
	margin-top: 80px;
	margin-bottom: 10px;
}
.team-member-description-doxed {
	color: white;
	margin-top: 80px;
	margin-bottom: 10px;
}
.plain-link {
	text-decoration: none;
	color: white;
}
.team-header {
	margin-bottom: 20px;
}
.team-header h2 {
	color: #fff;
	font-size: 2em;
	text-align: center;
}

/* Common styling for content sections */
.content-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 20px;
	margin: 20px;
}
.section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50vw;
	margin: 20px;
	color: #ffffff;
	border-radius: 15px;
	box-shadow: 0 0 5px 1px #26aaec;
	border: 4px solid #ffffff;
	flex: 1;
	min-width: 250px;
	max-width: 650px;
	background-color: #000000;
}
.how-to-container {
	width: 425px;
	height: auto;
	background-color: #000f28;
	color: white;
	border: 8px solid #000000;
	border-radius: 20px;
	margin-bottom: -15px;
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex;
	justify-content: center;
	align-items: cent;
	box-shadow: 0 0 5px 5px #b8d4fb;
	/* animation: swing 5.5s infinite alternate ease-in-out; */
}
.how-to-border {
	border: 4px solid #000000;
	border-radius: 20px;
	margin: 10px;
	width: 400px;
	height: auto;
}
.meme-container {
	width: 425px;
	height: auto;
	background-color: #000f28;
	color: white;
	border: 8px solid #000000;
	border-radius: 20px;
	margin-bottom: 45px;
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex;
	justify-content: center;
	align-items: cent;
	box-shadow: 0 0 5px 5px #b8d4fb;
	/* animation: swing 5.5s infinite alternate ease-in-out; */
}
.meme-border {
	border: 4px solid #000000;
	border-radius: 20px;
	margin: 10px;
	width: 400px;
	height: auto;
}

/*===============GLEEKIFY Page Styles ====================*/
.gleekify-container {
	position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
	justify-content: center;
    padding: 20px;
    margin-top: 100px;
	margin-left: -115px;
	width: 100%;
	box-sizing: border-box;
}

.primary-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
	user-select: none;
}
.additional-buttons {
	display: flex;
    flex-direction: column;
    gap: 10px;
	margin-top: -30px;
	user-select: none;
}
.canvas-toolbar-container {
    display: flex;
    align-items: start;
}
.canvas-frame-gleekify {
	margin: 0 auto;
	position: relative;
    border: 5px solid #dae7e9;
    background-image: url("/public/images/logos/gleek_website_bg_2.png");
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(210, 99, 99, 0.1);
    width: 600px;
    height: 600px;
}
.bottom-frame-buttons {
    position: absolute;
    bottom: -55px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    justify-content: center;
	width: 590px;
	user-select: none;
}
  .button-toolbar-gleekify {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-right: 20px;
}

.button-gleekify {
	background-color: #2d9bbd;
	color: #ffffff;
	border: none;
	padding: 10px 15px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 25px;
	margin: 0;
	transition: background-color 0.3s ease;
	font-family: "chimi", sans-serif;
	font-size: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 185px;
	height: 40px;
	user-select: none;
  }

  .modal-content {
	background-color: #b0dfed;
	border: 1px solid #888;
	border-radius: 15px;
	overflow-y: auto;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
	position: absolute;
	bottom: -115px;
	left: 730px;
	transform: translateX(-50%);
    width: 220px;
	height: 720px;
    max-height: 720px;
    overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	user-select: none;
  }
  .search-text {
	position: absolute;
	top: -25px;
	right: -265px;
	z-index: 1500;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #000;
    font-family: 'chimi', sans-serif;
    font-size: 22px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 0;
}
  .image-selection {
    display: flex;
	flex-direction: column;
	align-items: center;
    overflow-x: auto;
	padding-top: 10px;

}

/* Style for individual images */
.image-selection img {
    width: 100px;
    height: auto;
    cursor: pointer;
}

/* Footer styles */
.footer {
	width: 100%;
	padding: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.footer a {
	color: #fafafa;
	text-decoration: none;
	margin: 0 20px;
	display: flex;
	align-items: center;
}
.footer img {
	margin-right: 5px;
}

/* Mobile */
@media only screen and (max-width: 1024px) {
	.App {
		background-attachment: fixed;
		background-size: contain;
		background-position: center;
		background-repeat: repeat;
	}
	.game-top-level,
	.game-overlay,
	.info-message,
	.footer {
		display: none;
	}

	.rotate-soyjak {
		animation: rotate 5s linear infinite;
		width: 30px;
		height: 30px;
	}

	#nav-bar {
		width: 100%;
		background-color: #2d9bbd;
		overflow: hidden;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 3px solid #2c2c2c;
		z-index: 1000;
	}
	.nav-menu li {
		float: left;
		color: #fffdfd;
		margin-right: -12px;
		margin-left: -12px;
	}
	.nav-menu li a {
		display: block;
		color: white;
		text-align: center;
		text-decoration: none;
		font-size: 15px;
	}
	.gleek-info {
		position: relative;
		font-size: 16px;
		margin-top: 20px;
		margin-bottom: -40px;
		max-width: 290px;
		background-color: #6eb6c8;
		color: white;
		text-align: center;
		border: 5px solid #1e1d1d;
		border-radius: 25px;
		animation: swing 3.5s infinite alternate ease-in-out;
		box-shadow: 0 0 5px 1px #fbfbfb;
		line-height: 0.9;
		z-index: 0;
	}

	.timeline {
		flex-direction: column;
	}

	/* Adjusting phase styles for vertical layout */
	.timeline-phase {
		width: 70%;
		margin-bottom: 20px;
	}

	/* Adjust text size for readability on mobile */
	.timeline-phase h3,
	.timeline-phase ul li {
		font-size: smaller;
	}

	.gleekenomics-container {
		text-align: center;
		padding-top: 20px;
		padding-bottom: 20px;
		margin-top: -30px;
	}
	.gleekenomics-container-img {
		width: 220px;
		height: auto;
	}
	.how-to-container {
		max-width: 250px;
		position: relative;
		width: 300px;
		height: auto;
		z-index: 1;
	}

	.how-to-border {
		width: 250px;
		height: auto;
	}
	.meme-container {
		max-width: 250px;
		position: relative;
		width: 300px;
		height: auto;
		z-index: 1;
	}

	.meme-border {
		width: 250px;
		height: auto;
	}

	.content-container {
		margin-top: -15px;
	}

	/*GLEEKIFY*/
	.gleekify-container {

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		margin-top: 0px;
		padding-left: 250px;
		width: 100%;
		box-sizing: border-box;
	}
	.canvas-toolbar-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.canvas-frame-gleekify {
		margin: 0 auto;
		position: relative;
		border: 5px solid #dae7e9;
		background-image: url("/public/images/logos/gleek_website_bg_2.png");
		border-radius: 5px;
		box-shadow: 0 4px 8px rgba(210, 99, 99, 0.1);
		width: 97.5%;
		max-width: 1000px;
		height: auto;
	}
    .bottom-frame-buttons, .modal-mobile {
        flex-basis: 33%; /* Each takes up a third of the container width */
        display: flex;
		bottom: -415px;
        flex-direction: column;
        align-items: center;
        margin-left: -10px; 
        padding: 0; /* Reset padding for consistent spacing */
    }

	.primary-buttons {
        /* Convert to relative positioning for responsiveness */
        /* flex-basis: calc(50%);  */
        display: flex;
        flex-direction: column;
        align-items: center; /* Center-align the content */
        margin: 0;
		z-index: 1000;
		/* padding-right: 45px; */
    }
	.hide-button {
        /* Convert to relative positioning for responsiveness */
        /* flex-basis: calc(50%);  */
        display: flex;
        flex-direction: column;
        align-items: center; /* Center-align the content */
        margin: 0;
		/* padding-right: 45px; */
    }
	.additional-buttons {
        /* Convert to relative positioning for responsiveness */
        /* flex-basis: calc(50%);  */
        display: flex;
        flex-direction: column;
        align-items: center; /* Center-align the content */
        margin: 0;
		/* padding-right: 10px; */
    }
	/* .primary-buttons button, .hide-button button, .additional-buttons button {
        margin: 5px;
    } */
	  .button-toolbar-gleekify {
        display: grid;
		grid-template-columns: 1fr 1fr;
        flex-direction: row;
        flex-wrap:nowrap;
        justify-content: center;
        /* gap: 20px; */
        margin-top: 20px;
		margin-left: -80px;
	}
	.bottom-and-modal {
		display: flex;
        justify-content: space-around; /* Adjust as needed to space out columns */
        flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
        /* padding: 10px 0 */
	}
	.color-input {
		z-index: 1500;
	}
	.button-gleekify {
		background-color: #2d9bbd;
		color: #ffffff;
		border: none;
		padding: 10px 15px;
		border-radius: 5px;
		cursor: pointer;
		margin: 0;
		transition: background-color 0.3s ease;
		font-family: "chimi", sans-serif;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 40px;
	  }
	  .search-text-mobile {
        position: absolute;
        bottom: 339px;
		left: 190px;
        width: 105px; /* Example adjustment for width */
        padding: 8px;
        border-radius: 5px;
        border: 1px solid #ccc;
        background-color: #fff;
        color: #000;
        font-family: 'chimi', sans-serif;
        font-size: 18px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
		z-index: 0;
	}
	  .modal-mobile {
		position: absolute;
		bottom: -403px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
		padding-left: 280px;
	  }
	  .modal-content {
		background-color: #b0dfed;
		border: 1px solid #888;
		border-radius: 15px;
		overflow-y: auto;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
		position: absolute;
		left: calc(90%);
		top: -330px;
        /* transform: translateX(0%); */
		width: 125px;
		max-height: 390px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 18px;
		position: absolute;
	  }
	  .image-selection {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow-x: auto;
		padding-top: 10px;
	}

	.image-selection img {
		width: 100px;
		height: auto;
		cursor: pointer;
	}
}